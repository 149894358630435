import { useCallback } from 'react';
import { message } from 'antd';
import { useTranslation } from 'i18n';
import { useMutation } from '@apollo/client';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import REJECTION_REASONS_QUERY from '../rejectionReasonsQuery.gql';

const VALIDATE_FIELDS = {
  name: [isRequired],
};

const useRejectionReasonForm = ({ mutation, mutationName, onCancel }) => {
  const [saveMutation] = useMutation(mutation);
  const [organizationPk] = useCurrentOrganization();

  const {
    mutate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useErrorFieldsMutation(saveMutation);

  const { t } = useTranslation();
  const getValidationErrors = validator(VALIDATE_FIELDS);

  const onSave = useCallback(
    ({ name, reasonPk }) => {
      const validationErrors = getValidationErrors({
        name: name && name.trim(),
      });

      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk,
          name,
          pk: reasonPk,
        },
        refetchQueries: [
          { query: REJECTION_REASONS_QUERY, variables: { organizationPk } },
        ],
      })
        .then(() => {
          message.success(t('RejectionReasonSettings_message-saveSuccessful'));
          onCancel();
        })
        .catch(
          logAndShowGenericError(`${mutationName} rejected`, {
            name,
            organizationPk,
            reasonPk,
          }),
        );
    },
    [
      mutate,
      setFieldErrors,
      onCancel,
      organizationPk,
      getValidationErrors,
      mutationName,
      t,
    ],
  );

  return {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  };
};

export default useRejectionReasonForm;
