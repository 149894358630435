import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import Drawer from 'components/Drawer';
import CandidateHeader from 'pages/candidates/CandidateHeader';
import { XL, LG, MD, SM } from 'consts/media';
import useBrowserWidth from 'hooks/useBrowserWidth';
import CandidateWithApplicationsDrawer from '../CandidateWithApplications/CandidateWithApplicationsDrawer';

import style from './index.less';

function CandidateDrawer({
  selectedCandidatePk,
  activeApplicationPk,
  setActiveApplication,
  visible,
  onDrawerClose,
  onNewCandidateCreated,
  onCandidateDeleted,
  ...rest
}) {
  const drawerRef = useRef();
  const browserWidth = useBrowserWidth();

  let drawerWidth;
  if (browserWidth > XL) {
    drawerWidth = 1200;
  } else if (browserWidth > LG) {
    drawerWidth = browserWidth - 450;
  } else if (browserWidth > MD) {
    drawerWidth = browserWidth - 250;
  } else if (browserWidth > SM) {
    drawerWidth = browserWidth - 200;
  } else {
    drawerWidth = browserWidth;
  }

  // move focus on drawer if drawer is visible
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        drawerRef.current.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  // Close drawer when clicking outside of it
  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onDrawerClose();
      }
    }

    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, onDrawerClose]);

  return (
    <ConfigProvider
      getPopupContainer={() => drawerRef.current || document.body}
    >
      <Drawer
        className={style.drawer}
        width={drawerWidth}
        visible={visible}
        onClose={onDrawerClose}
        ref={drawerRef}
        {...rest}
      >
        {!selectedCandidatePk ? (
          <CandidateHeader
            onNewCandidateCreated={onNewCandidateCreated}
            onCandidateCreationCancelled={onDrawerClose}
            browserWidth={browserWidth}
          />
        ) : (
          <CandidateWithApplicationsDrawer
            candidatePk={selectedCandidatePk}
            activeApplicationPk={activeApplicationPk}
            setActiveApplication={setActiveApplication}
            onCandidateDeleted={onCandidateDeleted}
            popupContainerRef={drawerRef}
          />
        )}
      </Drawer>
    </ConfigProvider>
  );
}

CandidateDrawer.propTypes = {
  selectedCandidatePk: PropTypes.string,
  activeApplicationPk: PropTypes.string,
  setActiveApplication: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onDrawerClose: PropTypes.func.isRequired,
  onNewCandidateCreated: PropTypes.func,
  onCandidateDeleted: PropTypes.func,
};

export default CandidateDrawer;
