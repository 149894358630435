import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash-es/debounce';
import isArray from 'lodash-es/isArray';

import sessionStorage from 'utils/sessionStorage';
import qs from 'utils/urlParser';
import parseRangeFilters from 'utils/parseRangeFilters';
import getCustomFieldsFilters from 'utils/getCustomFieldsFilters';
import getCustomFieldsSelectedChoicesFilter from 'utils/getCustomFieldsSelectedChoicesFilter';
import { REJECTION_STATUS } from 'consts/RejectionStatusEnum';

// Stringify query params (filters, and query search) to format correct search path
const getSearchPath = (q, filters) => {
  const customFieldsFilters = getCustomFieldsFilters(filters);
  const customFieldsSelectedChoicesFilter =
    getCustomFieldsSelectedChoicesFilter(filters);

  return qs.stringify({
    q,
    position: filters.jobPositionFilter,
    stage: filters.stageFilter,
    rating: filters.ratingFilter,
    hasCv: filters.hasCvFilter,
    rejectionStatus: filters.rejectionStatusFilter,
    ...customFieldsFilters,
    ...customFieldsSelectedChoicesFilter,
  });
};

// Redirect to proper url that includes current filters and query search in path
const searchRedirect = (q, filters, navigate) => {
  // format search path correctly passing filters and q as query params
  const searchPath = getSearchPath(q, filters);
  const customFieldsFiltersKeys = Object.keys(filters).filter((f) =>
    f.includes('customFieldFilter'),
  );
  const customFieldsSelectedChoicesFiltersKeys = Object.keys(filters).filter(
    (f) => f.includes('customFieldMultichoiceFilter'),
  );

  // If filters and q are empty - clear the storage,
  // otherwise persist current searchPath in storage.
  if (
    !q &&
    Object.keys(filters).length > 0 &&
    filters.jobPositionFilter.length === 0 &&
    filters.stageFilter.length === 0 &&
    filters.ratingFilter.length === 0 &&
    filters.hasCvFilter === undefined &&
    filters.rejectedFilter === REJECTION_STATUS.ALL &&
    customFieldsFiltersKeys.length === 0 &&
    customFieldsSelectedChoicesFiltersKeys.length === 0
  ) {
    sessionStorage.clear('searchPath');
  } else {
    sessionStorage.persist({ searchPath });
  }

  // redirect to the url with current searchPath
  navigate(`/candidates?${searchPath}`);
};

const debounceSearchRedirect = debounce(
  (q, filters, navigate) => searchRedirect(q, filters, navigate),
  400,
);

/**
 * This hook handles searching and filtering candidates
 * It holds the state of current search value (q) and filters
 * and manages redirecting to appropriate url
 */
const useSearchFilters = ({ navigate, location }) => {
  // q - query search: text typed into the search box on candidates list
  const [q, setQueryParams] = useState('');
  // filters - all custom filters (set in CustomFilters)
  const [filters, setFilters] = useState({});

  // isInitialStateSet - indicates if state of q and filters is already set
  const [isInitialStateSet, setIsInitialStateSet] = useState(false);

  // If current url doesn't contain query string (location.search) and searchPath is saved in session storage,
  // redirect to updated url (using searchPath saved in storage)
  useEffect(() => {
    if (sessionStorage.get('searchPath') && !location.search) {
      setIsInitialStateSet(false);
      navigate(`/candidates?${sessionStorage.get('searchPath')}`);
    }
  }, [navigate, location.search]);

  // Effect for getting query params and setting them in states.
  // Get query parameters (from location search if exists or from session storage)
  // and save them in proper states.
  useEffect(() => {
    // run only when loading the page for the firs time
    if (!isInitialStateSet) {
      const savedFilters = sessionStorage.get('searchPath');

      // If url contains query string (location.search) - get query params from url,
      // otherwise get params from session storage - stored as searchPath (savedFilters).
      // If neither of those are provided, fallback to empty object.
      let queryParams = {};
      if (location.search) {
        queryParams = qs.parse(location.search);
      } else if (savedFilters) {
        queryParams = qs.parse(savedFilters);
      } else {
        queryParams = {};
      }

      if (queryParams.range) {
        queryParams.range = parseRangeFilters(queryParams.range);
      }

      setIsInitialStateSet(true);
      setQueryParams(queryParams.q);

      const customFieldsFilters = getCustomFieldsFilters(queryParams);
      const customFieldsSelectedChoicesFilter =
        getCustomFieldsSelectedChoicesFilter(queryParams);

      // setting filters from query params
      setFilters({
        jobPositionFilter: isArray(queryParams.position)
          ? queryParams.position
          : [],
        stageFilter: isArray(queryParams.stage) ? queryParams.stage : [],
        ratingFilter: isArray(queryParams.rating) ? queryParams.rating : [],
        hasCvFilter: queryParams.hasCv,
        rejectionStatusFilter:
          queryParams.rejectionStatus || REJECTION_STATUS.NON_REJECTED,
        ...customFieldsFilters,
        ...customFieldsSelectedChoicesFilter,
      });
    }
  }, [isInitialStateSet, location.search]);

  const onSearchInputChange = useCallback((e) => {
    setQueryParams(e.target.value);
  }, []);

  const onFiltersChange = useCallback(
    (filter, values) => {
      const newFilters = { ...filters, [filter]: values };

      // Reset stage filter if the job position changes
      if (filter === 'jobPositionFilter') {
        newFilters.stageFilter = [];
      }

      setFilters(newFilters);
    },
    [filters],
  );

  // Redirect to proper url based on filters and query search -
  // when any of them is updated, but after setting their initial state (after loading the page for the first time)
  useEffect(() => {
    if (isInitialStateSet) {
      debounceSearchRedirect(q, filters, navigate);
    }
  }, [q, filters, isInitialStateSet, navigate]);

  const onClearAllFilters = useCallback(() => {
    sessionStorage.persist({ searchPath: q ? `?q=${q}` : '' });
    setIsInitialStateSet(false);
    navigate(`/candidates${q ? `?q=${q}` : ''}`);
  }, [q, navigate]);

  return {
    q,
    filters,
    onSearchInputChange,
    onFiltersChange,
    onClearAllFilters,
  };
};

export default useSearchFilters;
