import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'i18n';
import useRejectionReasonForm from './useRejectionReasonForm';
import RejectionReasonFormModal from './RejectionReasonFormModal';

export const EDIT_REJECTION_REASON_MUTATION = gql`
  mutation UpdateRejectionReason(
    $pk: ID!
    $name: String!
    $organizationPk: String!
  ) {
    updateRejectionReason(
      pk: $pk
      name: $name
      organizationPk: $organizationPk
    ) {
      rejectionReason {
        id
        pk
        name
      }
    }
  }
`;

function RejectionReasonEditModal({
  reasonPk,
  initialName,
  isVisible,
  onCancel,
}) {
  const { t } = useTranslation();
  const {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useRejectionReasonForm({
    mutation: EDIT_REJECTION_REASON_MUTATION,
    mutationName: 'updateRejectionReason',
    onCancel,
  });

  const onEdit = useCallback(
    (reasonName) => onSave({ name: reasonName, reasonPk }),
    [onSave, reasonPk],
  );

  return (
    <RejectionReasonFormModal
      onSave={onEdit}
      title={t('RejectionReasonEditModal_title')}
      initialName={initialName}
      isVisible={isVisible}
      onCancel={onCancel}
      setFieldErrors={setFieldErrors}
      fieldErrors={fieldErrors}
      clearFieldError={clearFieldError}
      generalError={generalError}
      isSaving={isSaving}
    />
  );
}

RejectionReasonEditModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  reasonPk: PropTypes.string.isRequired,
  initialName: PropTypes.string.isRequired,
};

export default RejectionReasonEditModal;
