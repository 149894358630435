import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Select, message, Button } from 'antd';
import { useTranslation } from 'i18n';
import Modal from 'components/Modal';
import { logAndShowGenericError } from 'utils/log';
import APPLICATION_ACTIVITIES_QUERY from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
import Link from 'components/Link';
import REJECT_APPLICATION_MUTATION from './rejectApplicationMutation.gql';
import REJECTION_REASONS_QUERY from './rejectionReasonsQuery.gql';

function RejectApplicationModal({
  visible,
  onCancel,
  applicationId,
  organizationPk,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data, loading: reasonsLoading } = useQuery(REJECTION_REASONS_QUERY, {
    variables: { organizationPk },
    skip: !visible,
  });

  const [rejectApplication, { loading: rejectLoading }] = useMutation(
    REJECT_APPLICATION_MUTATION,
    {
      refetchQueries: [
        {
          query: APPLICATION_ACTIVITIES_QUERY,
          variables: { applicationPk: applicationId, organizationPk },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success(
          t('RejectApplicationModal_message-applicationRejected'),
        );
        onCancel();
      },
      onError: () => {
        message.error(
          t('RejectApplicationModal_message-applicationRejectError'),
        );
      },
    },
  );

  const handleReject = async () => {
    try {
      const { rejectReason } = await form.validateFields();
      await rejectApplication({
        variables: {
          applicationPk: applicationId,
          reasonPk: rejectReason,
          organizationPk,
        },
      });
    } catch (error) {
      if (error.name === 'ValidationError') {
        // Form validation errors are handled automatically
        return;
      }
      // Log any other errors that might occur during the process
      logAndShowGenericError('Error in handleReject:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={t('RejectApplicationModal_title-rejectApplication')}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('RejectApplicationModal_button-cancel')}
        </Button>,
        <Button
          key="reject"
          type="primary"
          onClick={handleReject}
          loading={rejectLoading}
        >
          {t('RejectApplicationModal_button-reject')}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="rejectReason"
          label={t('RejectApplicationModal_label-rejectionReason')}
          rules={[
            {
              required: true,
              message: t(
                'RejectApplicationModal_error-pleaseSelectRejectionReason',
              ),
            },
          ]}
        >
          <Select
            placeholder={t(
              'RejectApplicationModal_placeholder-selectRejectionReason',
            )}
            loading={reasonsLoading}
            disabled={reasonsLoading}
          >
            {data?.viewer?.rejectionReasons?.map((reason) => (
              <Select.Option key={reason.pk} value={reason.pk}>
                {t(`translation.${reason.name}`, { defaultValue: reason.name })}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Link to="/settings/rejection-reasons">
        {t('RejectionApplicationModal_link-manageRejectionReasons')}
      </Link>
    </Modal>
  );
}

RejectApplicationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  organizationPk: PropTypes.string.isRequired,
};

export default RejectApplicationModal;
