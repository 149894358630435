import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'i18n';
import useRejectionReasonForm from './useRejectionReasonForm';
import RejectionReasonFormModal from './RejectionReasonFormModal';

export const ADD_REJECTION_REASON_MUTATION = gql`
  mutation CreateRejectionReason($name: String!, $organizationPk: String!) {
    createRejectionReason(name: $name, organizationPk: $organizationPk) {
      rejectionReason {
        id
        pk
        name
      }
    }
  }
`;

function RejectionReasonAddModal({ isVisible, onCancel }) {
  const { t } = useTranslation();
  const {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useRejectionReasonForm({
    mutation: ADD_REJECTION_REASON_MUTATION,
    mutationName: 'createRejectionReason',
    onCancel,
  });

  const onAdd = useCallback(
    (reasonName) => onSave({ name: reasonName }),
    [onSave],
  );

  return (
    <RejectionReasonFormModal
      onSave={onAdd}
      title={t('RejectionReasonAddModal_title')}
      isVisible={isVisible}
      onCancel={onCancel}
      fieldErrors={fieldErrors}
      setFieldErrors={setFieldErrors}
      clearFieldError={clearFieldError}
      generalError={generalError}
      isSaving={isSaving}
    />
  );
}

RejectionReasonAddModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default RejectionReasonAddModal;
