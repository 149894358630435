import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import Activity from './Activity';

function ApplicationRejected({
  createdAt,
  createdBy,
  inApplicationContext,
  inJobPositionContext,
  application,
  rejectionReason,
  onClick,
  ...rest
}) {
  const { t } = useTranslation('translation');

  let actionContent;

  if (inApplicationContext) {
    actionContent = (
      <>
        {t('ApplicationRejected_message-rejectedApplication')}{' '}
        <b>{rejectionReason.name}</b>
      </>
    );
  } else {
    const i18nKey = `ApplicationRejected_message-rejectedApplicationExtended${
      inJobPositionContext ? 'InJobPosition' : ''
    }`;

    actionContent = (
      <Trans
        i18nKey={i18nKey}
        values={{
          candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
          position: application.jobPosition?.position || '',
          rejectionReason: rejectionReason?.name || '',
        }}
      >
        Rejected application of{' '}
        <Link
          onClick={onClick}
          to={`/candidates/${application.candidate.pk}/${application.pk}`}
        >
          {{
            candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
          }}
        </Link>{' '}
        {!inJobPositionContext && (
          <>
            for position <b>{{ position: application.jobPosition.position }}</b>
          </>
        )}{' '}
        with reason <b>{{ rejectionReason: rejectionReason.name }}</b>
      </Trans>
    );
  }

  return (
    <Activity
      icon="user"
      createdAt={createdAt}
      createdBy={createdBy}
      {...rest}
      action={actionContent}
    />
  );
}

ApplicationRejected.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.object,
  inApplicationContext: PropTypes.bool,
  inJobPositionContext: PropTypes.bool,
  application: PropTypes.shape({
    pk: PropTypes.string.isRequired,
    candidate: PropTypes.shape({
      pk: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    jobPosition: PropTypes.shape({
      position: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  rejectionReason: PropTypes.shape({
    name: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default ApplicationRejected;
