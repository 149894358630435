import React from 'react';
import PropTypes from 'prop-types';

import {
  BankOutlined,
  ClusterOutlined,
  DollarOutlined,
  LineChartOutlined,
  ProfileOutlined,
  SettingOutlined,
  TeamOutlined,
  MailOutlined,
  BellOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';
import { useTranslation } from 'i18n';
import Link from 'components/Link';

import Logo from 'components/Logo';
import useOrganizationModules from 'hooks/useOrganizationModules';
import {
  MODULE_PLAN_PAGE_VIEW,
  MODULE_BULK_EMAIL_SEND,
} from 'consts/organizationModules';

import { useLocation } from 'react-router';
import styles from './index.less';

const { Sider } = Layout;
const { Item: MenuItem, SubMenu } = Menu;

function SiderMenu({ onMenuClick, organizations }) {
  const location = useLocation();
  const { t } = useTranslation();

  const [hasPlanPageViewModule, hasBulkEmailingModule] = useOrganizationModules(
    [MODULE_PLAN_PAGE_VIEW, MODULE_BULK_EMAIL_SEND],
  );

  const selectedKey = location.pathname.split('/')[1];

  return (
    <Sider
      className={styles.sider}
      trigger={null}
      width={230}
      data-testid="menu"
      theme="light"
      aria-label="side menu"
    >
      <Link
        className={styles.orgContainer}
        to="/"
        aria-label="logo, link to home"
      >
        <Logo className={styles.logo} inMenu />
      </Link>
      <Menu
        theme="light"
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={onMenuClick}
        className={styles.mainMenu}
      >
        <MenuItem key="notifications">
          <BellOutlined aria-hidden />
          <span>{t('notifications')}</span>
        </MenuItem>
        <MenuItem key="candidates">
          <TeamOutlined aria-hidden />
          <span>{t('candidates')}</span>
        </MenuItem>
        <MenuItem key="positions">
          <ProfileOutlined aria-hidden />
          <span>{t('jobPositions')}</span>
        </MenuItem>
        {hasBulkEmailingModule && (
          <MenuItem key="emailing">
            <MailOutlined aria-hidden />
            <span>{t('bulkEmailing')}</span>
          </MenuItem>
        )}
        <SubMenu
          key="reporting"
          title={
            <>
              <LineChartOutlined aria-hidden />
              <span>{t('reporting')}</span>
            </>
          }
        >
          <MenuItem key="acquisition-report" role="none">
            {t('Menu_acquisitionReport')}
          </MenuItem>
          <MenuItem key="audience-report" role="none">
            {t('Menu_audienceReport')}
          </MenuItem>
          <MenuItem key="engagement-report" role="none">
            {t('Menu_engagementReport')}
          </MenuItem>
        </SubMenu>
        <Menu.Divider className={styles.divider} />
        {organizations.length > 1 && (
          <SubMenu
            key="switchOrg"
            title={
              <>
                <BankOutlined aria-hidden />
                <span>{t('switchOrgMenu')}</span>
              </>
            }
          >
            {organizations.map((o) => (
              <MenuItem key={o.pk} role="none">
                {o.name}
              </MenuItem>
            ))}
          </SubMenu>
        )}
        <MenuItem key="teamMembers">
          <ClusterOutlined aria-hidden />
          <span>{t('teamMembers')}</span>
        </MenuItem>
        {hasPlanPageViewModule && (
          <MenuItem key="plan">
            <DollarOutlined aria-hidden />
            <span>{t('planPage')}</span>
          </MenuItem>
        )}
        <SubMenu
          key="settings"
          title={
            <>
              <SettingOutlined aria-hidden />
              <span>{t('settings')}</span>
            </>
          }
        >
          <MenuItem key="user-settings" role="none">
            {t('Menu_userSettings')}
          </MenuItem>
          <MenuItem key="email-templates" role="none">
            {t('Menu_emailTemplates')}
          </MenuItem>
          <MenuItem key="custom-fields" role="none">
            {t('Menu_customFields')}
          </MenuItem>
          <MenuItem key="stage-lists" role="none">
            {t('Menu_stageLists')}
          </MenuItem>
          <MenuItem key="rejection-reasons" role="none">
            {t('Menu_rejectionReasons')}
          </MenuItem>
        </SubMenu>
        <MenuItem key="help">
          <QuestionCircleOutlined aria-hidden />
          <span>{t('help')}</span>
        </MenuItem>
      </Menu>
    </Sider>
  );
}

SiderMenu.propTypes = {
  onMenuClick: PropTypes.func,
  organizations: PropTypes.array,
};

export default SiderMenu;
