import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { compose } from 'apollo';

import Timeline from 'components/Timeline';
import Loader from 'components/Loader';

import ApplicationCreated from 'components/ActivityFeed/ApplicationCreated';
import OutboundEmail from 'components/ActivityFeed/OutboundEmail';
import InboundEmail from 'components/ActivityFeed/InboundEmail';
import BulkEmail from 'components/ActivityFeed/BulkEmail';
import ApplicationComment from 'components/ActivityFeed/ApplicationComment';
import ApplicationChangeRating from 'components/ActivityFeed/ApplicationChangeRating';
import ApplicationChangeAssign from 'components/ActivityFeed/ApplicationChangeAssign';
import ApplicationChangeStage from 'components/ActivityFeed/ApplicationChangeStage';
import ApplicationRejected from 'components/ActivityFeed/ApplicationRejected';
import ApplicationRestored from 'components/ActivityFeed/ApplicationRestored';

import styles from './index.less';

const MAP_TYPENAME_TO_COMPONENT = {
  ApplicationCreatedActivityNode: ApplicationCreated,
  ApplicationCommentActivityNode: ApplicationComment,
  ApplicationOutboundEmailActivityNode: OutboundEmail,
  ApplicationInboundEmailActivityNode: InboundEmail,
  BulkEmailActivityNode: BulkEmail,
  ApplicationChangeRatingActivityNode: ApplicationChangeRating,
  ApplicationChangeAssignActivityNode: ApplicationChangeAssign,
  ApplicationChangeStageActivityNode: ApplicationChangeStage,
  ApplicationRejectedActivityNode: ApplicationRejected,
  ApplicationRestoredActivityNode: ApplicationRestored,
};

const renderTimelineItem = (node) => {
  // eslint-disable-next-line no-underscore-dangle
  const TimelineActivity = MAP_TYPENAME_TO_COMPONENT[node.__typename];

  // Return null for unmapped activities to prevent entire application from collapsing
  if (!TimelineActivity) return null;

  return <TimelineActivity key={node.id} {...node} isSeen />;
};

function ActivityFeed({
  activities,
  isLoading,
  reversed,
  inJobPositionContext,
  inApplicationContext,
}) {
  let activitiesToMap = activities.slice(0);
  if (reversed) {
    activitiesToMap = activitiesToMap.reverse();
  }

  return (
    <>
      {activitiesToMap.length === 0 && isLoading && inApplicationContext && (
        <Loader fullSize />
      )}
      <Timeline
        className={styles.container}
        pending={isLoading && activitiesToMap.length === 0}
        data-testid="ActivityFeed"
      >
        {activitiesToMap.map(({ node }) =>
          renderTimelineItem({
            ...node,
            inJobPositionContext,
            inApplicationContext,
          }),
        )}
      </Timeline>
    </>
  );
}

ActivityFeed.propTypes = {
  activities: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  reversed: PropTypes.bool,
  inJobPositionContext: PropTypes.bool,
  inApplicationContext: PropTypes.bool,
};

export default compose(withTranslation('translation'))(ActivityFeed);
