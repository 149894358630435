import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import {
  MessageOutlined,
  MailOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import useToggleState from 'hooks/useToggleState';
import usePermissions from 'hooks/usePermissions';

import { PERMISSION_APPLICATION_COMMENT_ADD } from 'consts/permissions';

import Button from 'components/Form/Button';
import Tooltip from 'components/Tooltip';

import EmailComposeButton from 'components/EmailComposeButton';
import CommentModal from './CommentModal';
import RestoreApplicationButton from '../RestoreApplicationButton';

import styles from './index.less';

function ApplicationToolbar({
  application,
  contactDetails,
  candidate,
  onReject,
  isRejected,
  organizationPk,
  onApplicationRestored,
  hasApplicationManagePermission,
}) {
  const [isCommentFormOpen, onCommentFormOpen, onCommentFormClose] =
    useToggleState(false);

  const { t } = useTranslation();

  const [hasApplicationCommentPermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_APPLICATION_COMMENT_ADD]);

  const isJobPositionActive = application.jobPosition.isActive;

  const jobPositionInactiveMessage = useMemo(
    () =>
      !isJobPositionActive &&
      t('ApplicationMessage_tooltip--inactiveJobPosition'),
    [isJobPositionActive, t],
  );

  return (
    <div className={styles.toolbar}>
      <div className={styles.buttonsBar}>
        <Tooltip title={jobPositionInactiveMessage}>
          <span>
            <Button
              onClick={
                hasApplicationCommentPermission
                  ? onCommentFormOpen
                  : showNoPermissionMessage
              }
              disabled={!isJobPositionActive}
              size="default"
              data-testid="write-comment"
              data-role="write-comment"
            >
              <MessageOutlined aria-hidden />
              {t('addComment')}
            </Button>
          </span>
        </Tooltip>

        <EmailComposeButton
          application={application}
          contactDetails={contactDetails}
          candidate={candidate}
          icon={<MailOutlined aria-hidden />}
          size="default"
        >
          {t('composeEmail')}
        </EmailComposeButton>
        {isRejected ? (
          <RestoreApplicationButton
            applicationPk={application.pk}
            organizationPk={organizationPk}
            onSuccess={onApplicationRestored}
            candidateId={candidate.pk}
          />
        ) : (
          <Button
            onClick={onReject}
            size="default"
            icon={<CloseCircleOutlined />}
            disabled={!hasApplicationManagePermission}
          >
            {t('reject')}
          </Button>
        )}
      </div>

      <CommentModal
        application={application}
        candidatePk={candidate.pk}
        isFormOpen={isCommentFormOpen}
        onFormOpen={onCommentFormOpen}
        onFormClose={onCommentFormClose}
      />
    </div>
  );
}

ApplicationToolbar.propTypes = {
  application: PropTypes.object,
  contactDetails: PropTypes.array,
  candidate: PropTypes.object,
  onReject: PropTypes.func,
  isRejected: PropTypes.bool,
  organizationPk: PropTypes.string,
  onApplicationRestored: PropTypes.func,
  hasApplicationManagePermission: PropTypes.bool,
};

export default ApplicationToolbar;
