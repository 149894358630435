import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import { useTranslation } from 'i18n';
import { useNavigate } from 'react-router-dom';

import { FormOutlined } from '@ant-design/icons';

import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import { MANAGE_TEMPLATE } from './index';

import styles from './TemplateSelect.less';

function TemplateSelect({
  emailTemplatesList,
  onSelect,
  isDataLoading,
  fullWidth,
  persistSelection,
  popupContainer,
}) {
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();
  const navigate = useNavigate();
  const handleManageClick = useCallback(
    () => navigate('/settings/email-templates'),
    [navigate],
  );

  const onTemplateSelect = useCallback(
    (_, { value }) => {
      // 'MANAGE TEMPLATE' info is displayed as a position of Templates Menu,
      // clicking this item should not cause "SelectTemplate" action.
      if (value === MANAGE_TEMPLATE) {
        handleManageClick();
        return;
      }
      const selectedTemplate = emailTemplatesList.find(
        ({ node }) => node.id === value,
      );
      const { title, content, pk } = selectedTemplate.node;
      onSelect({ title, content, pk });
    },
    [emailTemplatesList, onSelect, handleManageClick],
  );

  const emailTemplatesChoices = useMemo(() => {
    const choices = emailTemplatesList.map(({ node }) => ({
      value: node.id,
      label: node.title,
      optionElement: (
        <div>
          <div className={styles.templateTitle}>{node.title}</div>
          <div className={styles.templateText}>{node.content}</div>
        </div>
      ),
    }));

    choices.push({
      value: MANAGE_TEMPLATE,
      label: t('EmailTemplates_manage'),
      optionElement: (
        <div className={styles.manageLink}>{t('EmailTemplates_manage')}</div>
      ),
    });

    return choices;
  }, [emailTemplatesList, t]);

  return (
    <Select
      data-testid="email-templates-select"
      autoClearSearchValue
      // we don't really want to select anything if selectedValue is not
      // provided explicitly
      value={persistSelection ? undefined : null}
      className={styles.select}
      choices={emailTemplatesChoices}
      dropdownMatchSelectWidth={browserWidth > XS ? 400 : undefined}
      placeholder={
        <>
          {' '}
          <FormOutlined className={styles.templateIcon} />
          {t('EmailTemplates')}
        </>
      }
      onSelect={onTemplateSelect}
      loading={isDataLoading}
      fullWidth={fullWidth}
      withSearch
      aria-label={t('EmailTemplates')}
      popupContainer={popupContainer}
    />
  );
}

TemplateSelect.propTypes = {
  isDataLoading: PropTypes.bool,
  emailTemplatesList: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        pk: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }),
    }),
  ),
  onSelect: PropTypes.func,
  fullWidth: PropTypes.bool,
  // if the selection should be remembered
  persistSelection: PropTypes.bool,
  popupContainer: PropTypes.object,
};

export default TemplateSelect;
