import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import Activity from './Activity';

function ApplicationRestored({
  createdAt,
  createdBy,
  inApplicationContext,
  inJobPositionContext,
  application,
  onClick,
  ...rest
}) {
  const { t } = useTranslation('translation');

  return (
    <Activity
      icon="user"
      createdAt={createdAt}
      createdBy={createdBy}
      {...rest}
      action={
        inApplicationContext ? (
          <>{t('ApplicationRestored_message-restoredApplication')}</>
        ) : (
          <Trans
            i18nKey={`ApplicationRestored_message-restoredApplicationExtended${
              inJobPositionContext ? 'InJobPosition' : ''
            }`}
            values={{
              candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
              position: application.jobPosition?.position || '',
            }}
          >
            Restored application of{' '}
            <Link
              onClick={onClick}
              to={`/candidates/${application.candidate.pk}/${application.pk}`}
            >
              {{
                candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
              }}
            </Link>{' '}
            {!inJobPositionContext && (
              <>
                for position{' '}
                <b>{{ position: application.jobPosition.position }}</b>
              </>
            )}
          </Trans>
        )
      }
    />
  );
}

ApplicationRestored.propTypes = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.shape({
    pk: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  inApplicationContext: PropTypes.bool,
  inJobPositionContext: PropTypes.bool,
  application: PropTypes.shape({
    pk: PropTypes.string.isRequired,
    candidate: PropTypes.shape({
      pk: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    jobPosition: PropTypes.shape({
      position: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func,
};

export default ApplicationRestored;
