import React, { useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';

import emailTemplatesListQuery from 'pages/settings/email-templates/emailTemplatesListQuery.gql';

import applicationDataForMagicTagsQuery from './applicationDataForMagicTagsQuery.gql';

import TemplateSelect from './TemplateSelect';

export const MANAGE_TEMPLATE = 'MANAGE_TEMPLATE';

const MAGIC_TAGS = [
  ['jobPosition', 'job_position_name'],
  ['firstName', 'first_name'],
  ['lastName', 'last_name'],
  ['organizationName', 'organization_name'],
];

const MAGIC_TAGS_WITH_REGEX = MAGIC_TAGS.map(([name, value]) => [
  name,
  new RegExp(`{{${value}}}`, 'g'),
]);

function TemplateSelectContainer({
  emailTemplatesList,
  onSelectEmailTemplate,
  organizationName,
  applicationDataForMagicTags,
  isEmailTemplatesListLoading,
  isApplicationDataForMagicTagsLoading,
}) {
  const isDataLoading = useMemo(
    () => isEmailTemplatesListLoading || isApplicationDataForMagicTagsLoading,
    [isEmailTemplatesListLoading, isApplicationDataForMagicTagsLoading],
  );

  const replaceMagicTags = useCallback(
    (string) => {
      const {
        jobPosition: { position },
        candidate: { firstName, lastName },
      } = applicationDataForMagicTags;

      const data = {
        jobPosition: position,
        firstName,
        lastName,
        organizationName,
      };

      const newString = MAGIC_TAGS_WITH_REGEX.reduce(
        (acc, [name, regex]) => acc.replace(regex, data[name]),
        string,
      );

      return newString;
    },
    [applicationDataForMagicTags, organizationName],
  );

  const onSelect = useCallback(
    ({ title, content }) => {
      const titleReplaced = replaceMagicTags(title);
      const contentReplaced = replaceMagicTags(content);
      onSelectEmailTemplate(titleReplaced, contentReplaced);
    },
    [replaceMagicTags, onSelectEmailTemplate],
  );

  const containerRef = useRef();

  return (
    <div ref={containerRef}>
      <TemplateSelect
        emailTemplatesList={emailTemplatesList}
        onSelect={onSelect}
        isDataLoading={isDataLoading}
        popupContainer={containerRef}
      />
    </div>
  );
}

TemplateSelectContainer.propTypes = {
  emailTemplatesList: PropTypes.array,
  onSelectEmailTemplate: PropTypes.func,
  organizationName: PropTypes.string,
  applicationDataForMagicTags: PropTypes.object,
  isEmailTemplatesListLoading: PropTypes.bool,
  isApplicationDataForMagicTagsLoading: PropTypes.bool,
};

export default compose(
  withOrganizationPk,
  graphql(emailTemplatesListQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      emailTemplatesList: !loading && viewer ? viewer.emailTemplates.edges : [],
      isEmailTemplatesListLoading: loading,
    }),
  }),
  graphql(applicationDataForMagicTagsQuery, {
    options: ({ organizationPk, applicationPk }) => ({
      variables: { organizationPk, applicationPk },
    }),
    props: ({ data: { viewer, loading } }) => ({
      applicationDataForMagicTags:
        !loading && viewer ? viewer.jobApplications.edges[0].node : {},
      isApplicationDataForMagicTagsLoading: loading,
    }),
  }),
)(TemplateSelectContainer);
