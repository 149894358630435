import React from 'react';
import Link from 'components/Link';
import { useTranslation } from 'i18n';
import styles from './index.less';

function SettingsMenu() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.linkWrapper}>
        <Link to="/settings/user-settings">
          {t('SettingsLink_userSettings')}
        </Link>
      </div>
      <div className={styles.linkWrapper}>
        <Link to="/settings/email-templates">
          {t('SettingsLink_emailTemplates')}
        </Link>
      </div>
      <div className={styles.linkWrapper}>
        <Link to="/settings/custom-fields">
          {t('SettingsLink_customFields')}
        </Link>
      </div>
      <div className={styles.linkWrapper}>
        <Link to="/settings/stage-lists">{t('SettingsLink_stageLists')}</Link>
      </div>
      <div className={styles.linkWrapper}>
        <Link to="/settings/rejection-reasons">
          {t('SettingsLink_rejectionReasons')}
        </Link>
      </div>
    </>
  );
}

export default SettingsMenu;
