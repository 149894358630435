import React from 'react';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import changeNotificationStatusMutation from 'hooks/useNotificationStatusChange/changeNotificationStatusMutation.gql';
import { NOTIFICATION_STATUS } from 'pages/notifications/NotificationsScreen/Notification';
import { useTranslation } from 'i18n';

import { BellOutlined } from '@ant-design/icons';

export default function ReadAllNotificationButton({ notificationsList }) {
  const [changeNotificationStatus] = useMutation(
    changeNotificationStatusMutation,
  );
  const { t } = useTranslation();

  const handleReadAllClick = () => {
    if (notificationsList) {
      const unreadNotificationsPks = notificationsList
        .filter(({ node }) => node.status !== NOTIFICATION_STATUS.READ)
        .map(({ node }) => node.pk);

      if (unreadNotificationsPks.length > 0) {
        const status = NOTIFICATION_STATUS.READ;
        changeNotificationStatus({
          variables: { notificationsPks: unreadNotificationsPks, status },
          optimisticResponse: {
            notificationStatusChange: {
              errors: null,
              notifications: unreadNotificationsPks.map((pk) => ({
                id: pk,
                status,
                __typename: 'NotificationNode',
              })),
              __typename: 'ChangeNotificationStatusMutation',
            },
          },
        });
      }
    }
  };

  return (
    <Button
      onClick={(e) => {
        handleReadAllClick(notificationsList);
        // After clicking, the focus remains, we have to remove it
        e.currentTarget.blur();
      }}
      size="default"
      icon={<BellOutlined />}
      disabled={!notificationsList}
      data-testid="read-all-notification-button"
    >
      {t('ReadAllNotificationButton-notification-button')}
    </Button>
  );
}

ReadAllNotificationButton.propTypes = {
  notificationsList: PropTypes.array,
};
