import React from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { logAndShowGenericError } from 'utils/log';
import APPLICATION_ACTIVITIES_QUERY from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
import RESTORE_APPLICATION_MUTATION from '../restoreApplicationMutation.gql';

function RestoreApplicationButton({
  applicationPk,
  organizationPk,
  onSuccess,
}) {
  const { t } = useTranslation();
  const [restoreApplication, { loading }] = useMutation(
    RESTORE_APPLICATION_MUTATION,
    {
      refetchQueries: [
        {
          query: APPLICATION_ACTIVITIES_QUERY,
          variables: { applicationPk, organizationPk },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success(
          t('RestoreApplicationButton_message-applicationRestored'),
        );
        if (onSuccess) onSuccess();
      },
      onError: () => {
        message.error(
          t('RestoreApplicationButton_message-applicationRestoreError'),
        );
      },
    },
  );

  const handleRestore = async () => {
    try {
      await restoreApplication({
        variables: { applicationPk, organizationPk },
      });
    } catch (error) {
      logAndShowGenericError('Error in handleRestore:', error);
    }
  };

  return (
    <Button onClick={handleRestore} loading={loading} icon={<UndoOutlined />}>
      {t('RestoreApplicationButton_button-restoreApplication')}
    </Button>
  );
}

RestoreApplicationButton.propTypes = {
  applicationPk: PropTypes.string.isRequired,
  organizationPk: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default RestoreApplicationButton;
